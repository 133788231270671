import { IonApp, IonRouterOutlet, IonText, setupIonicReact } from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';
import React from 'react';
import { Navigate, BrowserRouter, Route, Routes } from 'react-router-dom';

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/display.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/padding.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';


/* Theme variables */
import "swiper/css";
import 'swiper/css/effect-cards';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import 'swiper/swiper-bundle.css';

import './theme/variables.css';


import GoogleAuthPage from './pages/GoogleAuthPage';
import List from './pages/List';
import Home from './pages/Home';
import Login from './pages/Login';
import { todoStore } from './features/todo-matrix/service';
import { matrixBridge } from './features/todo-matrix/service/bridge';
import { StoreExecutor, runMain } from 'qume';
import { QumeProvider } from 'qume-react';
import { initMatrixEvents, matrixStore } from './features/todo-matrix/service/MatrixEventSource';


setupIonicReact({ mode: 'ios' });

const mainStore = runMain({ todoStore, matrixStore, matrixBridge })
initMatrixEvents().forEach(ev => mainStore.publish(ev))


const App: React.FC = () => (
  <IonApp>
    <QumeProvider main={mainStore}>
      <BrowserRouter >
        <Routes>
          <Route path="/oauth/google" element={<GoogleAuthPage />} />
          <Route path="/lists/:listId" element={<List />} />
          <Route path="/" element={<Login />} />
          <Route path="/home" element={<Home />} />
          <Route path="*" element={<Navigate to="/" replace={true} />} />
        </Routes>
      </BrowserRouter>
    </QumeProvider>
  </IonApp>
);

export default App;
