"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.focus = void 0;
const Pack_1 = require("../Pack");
const FOCUS = 'FOCUS';
function focus(prev, fieldName) {
    const focus0 = (0, Pack_1.flatMapValuePack)((v, k, seqId, deleted, noEffect) => fieldName in v && !deleted ?
        (0, Pack_1.fullPack)(k, v[fieldName], seqId, deleted, noEffect) : (0, Pack_1.emptyPack)());
    return {
        type: FOCUS,
        locale: prev.locale,
        state: path => prev.state(path).map(focus0),
        keys: path => prev.keys(path),
        value: path => keys => prev.value(path)(keys).map(focus0)
    };
}
exports.focus = focus;
