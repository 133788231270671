import { CREATE_TODO, TODO, ToDoFact, TODO_ACTIVATED, TODO_COMPLETED, TODO_CREATED, USER_LOGGED_IN, TODO_LIST_CREATED, TODO_MESSAGE } from './types'
import React, { useContext, useState, createContext, useEffect, useCallback, useRef, useMemo } from 'react'
import { Context, EventSource, HasType, InMemoryStorage, Pack, PackSubscriber, QueryObj, QueryRecord, QueryStore, QueryTail, Reader, State, Storage } from 'qume';
import * as _ from "lodash"
import { WebrtcProvider } from 'y-webrtc'

import { scope } from 'qume'


import { RoomEvent, LoginResponse, createClient, EventType, MatrixClient, MsgType, MatrixEvent } from 'matrix-js-sdk';
import { ACCESS_TOKEN_SET, DEVICE_ID_SET, EVENTS_SYNCED, HOMESERVER_SET, MatrixFact, matrixStore, SEND_EVENT, TIMELINE_EVENT, USER_ID_SET } from './MatrixEventSource';



const { query, join, store } = scope<ToDoFact>()

export const todoStore = store({
  user: query(USER_LOGGED_IN).byKey(() => ''),
  lists: query(TODO_LIST_CREATED).byKey(v => v.id),

  created: query(CREATE_TODO)
    .random((v, r) => ({ ...v, id: r.toString() }))
    .by.id
    .publish(TODO_CREATED),

  joined: join({
    id: query(TODO_CREATED).byId().map(v => v.id),
    title: query(TODO_CREATED).byId().map(v => v.title),
    listId: query(TODO_CREATED).byId().map(v => v.listId),
    active: query(
      query(TODO_CREATED).byId().as(true),
      query(TODO_ACTIVATED).byId().as(true),
      query(TODO_COMPLETED).byId().as(false),
    )
  }).process(TODO),

  all: query(TODO)
    .byKey(v => v.listId)
    .addKey(v => v.id),

  active: query(TODO)
    .filter(todo => todo.active)
    .byKey(v => v.listId)
    .addKey(v => v.id),

  messages: query(TODO_MESSAGE)
    .byKey(v => v.listId)
    .map(v => [v])
    .reduce((v1, v2) => v1.concat(v2))

  ,

})




