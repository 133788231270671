"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.byKey = void 0;
const base_1 = require("../base");
const Pack_1 = require("../Pack");
const Context_1 = require("../Context");
const BY_KEY = 'BY_KEY';
function byKey(prev, f) {
    const locale = (0, base_1.pathKey)(prev.locale, BY_KEY);
    return {
        type: BY_KEY,
        locale: locale,
        state: path => prev.state(path)
            .map((0, Pack_1.mapKeyPack)(f))
            .flatTap(Context_1.Context.writeByKeys((0, base_1.pathKey)(path, locale))),
        keys: Context_1.Context.allKeys(locale),
        value: Context_1.Context.valueByKeys(locale)
    };
}
exports.byKey = byKey;
