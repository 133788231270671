"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.addKey = void 0;
const base_1 = require("../base");
const Pack_1 = require("../Pack");
const Context_1 = require("../Context");
const ADD_KEY = 'ADD_KEY';
// TODO make it stateless
function addKey(prev, f) {
    const locale = (0, base_1.pathKey)(prev.locale, ADD_KEY);
    return {
        type: ADD_KEY,
        locale: locale,
        // state: path => prev.state(path).map(mapKeyPack((v, k) => pathKey(k, f(v, k))))
        //   .flatTap(Context.writeByKeys<S, K0, R>(pathKey(locale, path))),
        state: path => prev.state(path).map((0, Pack_1.mapKeyPack)((v, k) => (0, base_1.pathKey)(k, f(v, k))))
            .flatTap(Context_1.Context.writeByKeys((0, base_1.pathKey)(path, locale))),
        keys: Context_1.Context.allKeys(locale),
        value: Context_1.Context.valueByKeys(locale)
    };
}
exports.addKey = addKey;
