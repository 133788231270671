"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.runMain = exports.MainStore = exports.StorageListener = exports.LoopbackEventSource = void 0;
const _ = __importStar(require("lodash"));
const QueryTail_1 = require("./QueryTail");
const StoreExecutor_1 = require("./StoreExecutor");
class LoopbackEventSource {
    constructor() {
        this._subscribers = [];
    }
    publish(events, self) {
        if (!_.isArray(events))
            events = [events];
        _.forEach(this._subscribers.filter(s => !self || s != self), subs => subs({ events: events, noEffect: false }));
    }
    subscribe(subs) {
        this._subscribers = [...this._subscribers, ...[subs]];
    }
}
exports.LoopbackEventSource = LoopbackEventSource;
class StorageListener {
    constructor(qt, key, storage, handler) {
        this.qt = qt;
        this.key = key;
        this.storage = storage;
        this.handler = handler;
    }
    fire() {
        QueryTail_1.QueryTail.readRecord(this.qt, this.key)
            .run(this.storage)
            .then(value => { this.handler(value); });
    }
}
exports.StorageListener = StorageListener;
class MainStore {
    constructor(stores) {
        this.eventSource = new LoopbackEventSource();
        this.executors = _.map(stores, (store, name) => new StoreExecutor_1.StoreExecutor(store, name, this.eventSource));
    }
    allExecutors() { return this.executors; }
    getExecutor(store) {
        const executor = _.find(this.executors, ex => ex.store === store);
        if (!executor)
            throw new Error("QueryStore is not in the Main");
        else
            return executor;
    }
    get inprogress() {
        return _.reduce(this.executors, (acc, ex) => acc.then(() => ex.inprogress), Promise.resolve());
    }
    storeInprogress(store) { return this.getExecutor(store).inprogress; }
    publish(event) { this.eventSource.publish(event); }
    listen(qt, key, cb) {
        if (!qt.store)
            throw new Error("QueryTail is not in the store");
        this.getExecutor(qt.store).listen(qt, key, cb);
    }
    unlisten(qt, cb) {
        if (!qt.store)
            throw new Error("QueryTail is not in the store");
        this.getExecutor(qt.store).unlisten(cb);
    }
    read(qt, path = '') {
        if (!qt.store)
            throw new Error("QueryTail is not in the store");
        return this.getExecutor(qt.store).read(qt, path);
    }
}
exports.MainStore = MainStore;
function runMain(items) {
    return new MainStore(items);
}
exports.runMain = runMain;
