"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.merge = void 0;
const base_1 = require("../base");
const Pack_1 = require("../Pack");
const _ = __importStar(require("lodash"));
const State_1 = require("../State");
const Context_1 = require("../Context");
const Reader_1 = require("../Reader");
const MERGE = 'MERGE';
function merge(prev) {
    return {
        type: MERGE,
        locale: '',
        keys: path => _.reduce(prev, (acc, line, fieldKey) => line.keys((0, base_1.pathKey)(path, fieldKey + ''))
            .flatMap(keys => acc.map(accKeys => accKeys.concat(keys))), Reader_1.Reader.pure([])).map(_.uniq),
        state: prefixPath => Context_1.Context.getInlet().flatMap(events => State_1.State.traverseArr(events, event => State_1.State.traverseArr(prev, (qt, k) => Context_1.Context.setInlet([event])
            .asF(qt.state((0, base_1.pathKey)(prefixPath, k + '')))).map(packs => _.find(packs, v => !_.isEmpty(v)) || (0, Pack_1.emptyPack)())).map(Pack_1.flattenPack).flatTap(() => Context_1.Context.setInlet(events))),
        value: prefixPath => keys => Reader_1.Reader.traverseArr(prev, (qt, k) => qt.value((0, base_1.pathKey)(prefixPath, k + ''))(keys))
            .map(Pack_1.flattenPack)
            .map(pack => 
        // find max seqId per each key
        _.chain(pack).groupBy(v => v.key).map(packByKey => _.chain(packByKey)
            .sortBy(entry => entry.seqId)
            .reverse().head().value() || (0, Pack_1.emptyPack)()).value()),
    };
}
exports.merge = merge;
