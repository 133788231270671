"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.empty = void 0;
const Pack_1 = require("../Pack");
const State_1 = require("../State");
const Context_1 = require("../Context");
const EMPTY = 'EMPTY';
function empty() {
    return {
        type: EMPTY,
        locale: '',
        keys: Context_1.Context.emptyKeys(),
        state: () => State_1.State.pure((0, Pack_1.emptyPack)()),
        value: Context_1.Context.emptyValue(),
    };
}
exports.empty = empty;
