"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.once = void 0;
const State_1 = require("../State");
const Context_1 = require("../Context");
const _ = __importStar(require("lodash"));
const ONCE = 'ONCE';
function once(prev) {
    return {
        type: ONCE,
        locale: prev.locale,
        keys: prev.keys,
        state: prefixPath => Context_1.Context.getInlet().flatMap(pack => {
            const keys = _.uniq(pack.map(en => en.key));
            const valuesF = prev.value(prefixPath)(keys).run;
            return State_1.State.apply(ctx => valuesF(ctx).then(v => [ctx, v]))
                .map(vs => _.filter(pack, en => !_.some(vs, v => v.key == en.key)))
                .flatTap(pack => Context_1.Context.setInlet(pack))
                .flatMap(() => prev.state(prefixPath));
        }),
        value: prev.value
    };
}
exports.once = once;
