import { CREATE_TODO, ToDoFact, TODO_ACTIVATED, TODO_COMPLETED, TODO_CREATED, USER_LOGGED_IN, TODO_LIST_CREATED, CREATE_TODO_LIST, SYNC_TODO_LIST, SEND_TODO_MESSAGE, TODO_MESSAGE } from './types'
import React, { useContext, useState, createContext, useEffect, useCallback, useRef, useMemo } from 'react'
import { Context, EventSource, HasType, InMemoryStorage, Pack, QueryObj, QueryRecord, QueryStore, QueryTail, Reader, State, Storage } from 'qume';
import * as _ from "lodash"
import { WebrtcProvider } from 'y-webrtc'

import { scope } from 'qume'
import { fullPack } from 'qume/dist/internal/Pack';


import { RoomEvent, LoginResponse, createClient, EventType, MatrixClient, MsgType, MatrixEvent } from 'matrix-js-sdk';
import { ACCESS_TOKEN_SET, CREATE_ROOM, DEVICE_ID_SET, EVENTS_SYNCED, HOMESERVER_SET, MatrixFact, matrixStore, ROOMS_EXTRACTED, SEND_EVENT, SEND_MESSAGE, SYNC_ROOM_EVENTS, TIMELINE_EVENT, USER_ID_SET, USER_PROFILE_EXTRACTED } from './MatrixEventSource';



const eventType = 'app.ulolo.todo'
const { query, store } = scope<MatrixFact | ToDoFact>()

export const matrixBridge = store({

  userProfile: query(USER_PROFILE_EXTRACTED)
    .filter(user => user.displayname != undefined)
    .map(user => ({ name: user.displayname, avatarUrl: user.avatar_url }))
    .publish(USER_LOGGED_IN)
  ,

  createToDoList: query(CREATE_TODO_LIST)
    .publish(CREATE_ROOM),

  sendMessage: query(SEND_TODO_MESSAGE)
    .map(ev => ({ roomId: ev.listId, text: ev.text }))
    .publish(SEND_MESSAGE),

  toDoLists: query(ROOMS_EXTRACTED)
    .mapArray(ev => ev.rooms)
    .map(ev => ({ id: ev.roomId, title: ev.name }))
    .publish(TODO_LIST_CREATED)
  ,

  syncToDoList: query(SYNC_TODO_LIST)
    .map(v => ({ roomId: v.listId }))
    .publish(SYNC_ROOM_EVENTS),

  init: query(EVENTS_SYNCED)
    .mapArray(ev => ev.events)
    .filter(ev => ev.type == eventType)
    .map(ev => ev.content)
    .publish(),

  in: query(TIMELINE_EVENT)
    .filter(ev => ev.event.getType() == eventType)
    .map(ev => ev.event.getContent())
    .publish(),

  initMessages: query(EVENTS_SYNCED)
    .mapArray(ev => ev.events)
    .filter(ev => ev.type == EventType.RoomMessage)
    .map(ev => ({ id: ev.event_id, listId: ev.room_id, text: ev.content.body }))
    .publish(TODO_MESSAGE),

  inMessage: query(TIMELINE_EVENT)
    .filter(ev => ev.event.getType() == EventType.RoomMessage)
    .map(ev => ({ id: ev.event.getId(), listId: ev.event.getRoomId(), text: ev.event.getContent().body }))
    .publish(TODO_MESSAGE),

  out: query(
    query(TODO_CREATED),
    query(TODO_ACTIVATED),
    query(TODO_COMPLETED),
  )
    .map(ev => ({
      type: SEND_EVENT,
      eventType: eventType,
      roomId: ev.listId,
      body: ev
    }))
    .publish()
  ,
})









