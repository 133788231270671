import React, { useEffect } from 'react';
import { IonCard, IonCardContent, IonCardHeader, IonCardSubtitle, IonCardTitle, IonContent, IonPage, IonText } from '@ionic/react';

import { useNavigate, useSearchParams } from "react-router-dom";
import { LOGIN_TOKEN_SET, MatrixFact, matrixStore } from '../features/todo-matrix/service/MatrixEventSource';
import { usePublisher } from 'qume-react';


const GoogleAuthPage: React.FC = () => {
  const [params,] = useSearchParams()
  const code = params.get('loginToken')

  const navigate = useNavigate()
  const publish = usePublisher<MatrixFact>()

  useEffect(() => {
    console.log('LOGIN_TOKEN_SET published', code)
    publish({ type: LOGIN_TOKEN_SET, loginToken: code! })
    navigate("/home")
  }, [code])
  return (
    <IonPage>
      <div id="page" className="min-h-screen bg-gradient-to-br from-indigo-500 to-purple-500">
        <div className="relative">

          <div className="bg-white rounded-b-3xl p-2 drop-shadow-md"></div>

          <div className="px-8 pt-4 font-sans text-white">
            <div className="text-xl ">January, 15</div>
          </div>

        </div>

      </div >

    </IonPage >
  );
}
export default GoogleAuthPage;
