
export const USER_LOGGED_IN = 'USER_LOGGED_IN'

export const CREATE_TODO_LIST = 'CREATE_TODO_LIST'
export const SYNC_TODO_LIST = 'SYNC_TODO_LIST'
export const CREATE_TODO = 'CREATE_TODO'
export const SEND_TODO_MESSAGE = 'SEND_TODO_MESSAGE'

export const TODO_LIST_CREATED = 'TODO_LIST_CREATED'
export const TODO_CREATED = 'TODO_CREATED'
export const TODO_ACTIVATED = 'TODO_ACTIVATED'
export const TODO_COMPLETED = 'TODO_COMPLETED'
export const TODO_MESSAGE = 'TODO_MESSAGE'

export const TODO = 'TODO'

export type UserLoggedIn = { type: typeof USER_LOGGED_IN, name: string, avatarUrl?: string }

export type SyncTodoList = { type: typeof SYNC_TODO_LIST, listId: string }
export type CreateToDoList = { type: typeof CREATE_TODO_LIST, title: string }
export type CreateToDo = { type: typeof CREATE_TODO, listId: string, title: string }
export type SendToDoMessage = { type: typeof SEND_TODO_MESSAGE, listId: string, text: string }

export type ToDoListCreated = { type: typeof TODO_LIST_CREATED, id: string, title: string }
export type ToDoCreated = { type: typeof TODO_CREATED, listId: string, id: string, title: string }
export type ToDoActivated = { type: typeof TODO_ACTIVATED, listId: string, id: string }
export type ToDoCompleted = { type: typeof TODO_COMPLETED, listId: string, id: string }
export type ToDo = { type: typeof TODO, listId: string, id: string, title: string, active: boolean }

export type ToDoMessage = { type: typeof TODO_MESSAGE, id: string, listId: string, text: string }

export type ToDoFact =
  | UserLoggedIn
  | SyncTodoList
  | CreateToDoList
  | CreateToDo
  | SendToDoMessage
  | ToDoListCreated
  | ToDoCreated
  | ToDoActivated
  | ToDoCompleted
  | ToDo
  | ToDoMessage

