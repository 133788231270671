"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.reduce = void 0;
const base_1 = require("../base");
const Pack_1 = require("../Pack");
const State_1 = require("../State");
const Context_1 = require("../Context");
const REDUCE = 'REDUCE';
function reduce(prev, f) {
    const locale = (0, base_1.pathKey)(prev.locale, REDUCE);
    return {
        type: REDUCE,
        locale: locale,
        state: path => prev.state(path).flatMap(reduceState((0, base_1.pathKey)(path, locale), f)),
        keys: Context_1.Context.allKeys(locale),
        value: Context_1.Context.valueByKeys(locale)
    };
}
exports.reduce = reduce;
function reduceState(path, f) {
    return pack => State_1.State.traverseArr(pack, entry => {
        const key = (0, base_1.pathKey)(path, entry.key);
        if (entry.deleted)
            return Context_1.Context.delete(key).as((0, Pack_1.emptyPack)());
        else
            return Context_1.Context
                .readOpt(key)
                .map(r => r
                ? ({ seqId: r.seqId, value: f(r.value, entry.value) })
                : ({ seqId: entry.seqId, value: entry.value }))
                .flatTap(r => Context_1.Context.storeValue(key, r.value, entry.seqId))
                .map(v => (0, Pack_1.fullPack)(entry.key, v.value, v.seqId, entry.deleted, entry.noEffect));
    }).map(Pack_1.flattenPack);
}
