"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.mapArray = void 0;
const Pack_1 = require("../Pack");
const MAPPED_ARRAY = 'MAPPED_ARRAY';
function mapArray(prev, f) {
    return {
        type: MAPPED_ARRAY,
        locale: prev.locale,
        keys: prev.keys,
        state: path => prev.state(path).map((0, Pack_1.semiFlatMapPack)(f)),
        value: path => keys => prev.value(path)(keys).map((0, Pack_1.semiFlatMapPack)(f))
    };
}
exports.mapArray = mapArray;
