"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.noEffect = void 0;
const Pack_1 = require("../Pack");
const NO_EFFECT = 'NO_EFFECT';
function noEffect(prev) {
    const noEffect0 = (0, Pack_1.flatMapPack)((value, key, seqId, deleted) => (0, Pack_1.fullPack)(key, value, seqId, deleted, true));
    return {
        type: NO_EFFECT,
        locale: prev.locale,
        keys: prev.keys,
        state: path => prev.state(path).map(noEffect0),
        value: path => keys => prev.value(path)(keys).map(noEffect0)
    };
}
exports.noEffect = noEffect;
