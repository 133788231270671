import React, { useContext, useState, createContext, useEffect, useCallback, useRef, useMemo } from 'react'
import { Context, EventSource, HasType, InMemoryStorage, Pack, PackSubscriber, QueryObj, QueryRecord, QueryStore, QueryTail, Reader, State, Storage, StoreExecutor } from 'qume';
import * as _ from "lodash"
import * as Y from 'yjs'
import { WebrtcProvider } from 'y-webrtc'

import { scope } from 'qume'
import { fullPack, entry } from 'qume/dist/internal/Pack';


import { RoomEvent, LoginResponse, createClient, EventType, MatrixClient, MsgType, MatrixEvent, IEventWithRoomId, RoomStateEvent, Visibility, ClientEvent, SyncState, Room, IContent } from 'matrix-js-sdk';
import { exec } from 'child_process';



const homeserver = "https://matrix.ulolo.app/"
const userId = "@maxkorolev:ulolo.app"
// const accessToken = "syt_bWF4a29yb2xldg_PXEVFrIpEAOnXQNsIGWy_1gYdGy"
const deviceId = "IHTLDCEBQY"
// const roomId = "!RzZRfLxPAOgeTgpseG:ulolo.app"



export const HOMESERVER_SET = 'HOMESERVER_SET'
export const USER_ID_SET = 'USER_ID_SET'
export const USER_PASSWORD_SET = 'USER_PASSWORD_SET'
export const ACCESS_TOKEN_SET = 'ACCESS_TOKEN_SET'
export const LOGIN_TOKEN_SET = 'LOGIN_TOKEN_SET'
export const DEVICE_ID_SET = 'DEVICE_ID_SET'
export const CLIENT_CREATED = 'CLIENT_CREATED'
export const CLIENT_STARTED = 'CLIENT_STARTED'
export const CLIENT_INIT = 'CLIENT_INIT'
export const USER_PROFILE_EXTRACTED = 'USER_PROFILE_EXTRACTED'

export const TIMELINE_EVENT = 'TIMELINE_EVENT'
export const ROOM_MEMBER_EVENT = 'ROOM_MEMBER_EVENT'

export const ROOMS_EXTRACTED = 'ROOMS_EXTRACTED'
export const CREATE_ROOM = 'CREATE_ROOM'
export const SYNC_ROOM_EVENTS = 'SYNC_ROOM_EVENTS'
export const ROOM_EVENTS_SYNCED = 'ROOM_EVENTS_SYNCED'

export const EVENTS_SYNCED = 'EVENTS_SYNCED'
export const SEND_EVENT = 'SEND_EVENT'
export const SEND_MESSAGE = 'SEND_MESSAGE'


export type BaseUrlSet = { type: typeof HOMESERVER_SET, homeserver: string }
export type UserIdSet = { type: typeof USER_ID_SET, userId: string }
export type UserPasswordSet = { type: typeof USER_PASSWORD_SET, password: string }
export type LoginTokenSet = { type: typeof LOGIN_TOKEN_SET, loginToken: string }
export type AccessTokenSet = { type: typeof ACCESS_TOKEN_SET, accessToken: string }
export type DeviceIdSet = { type: typeof DEVICE_ID_SET, deviceId: string }
export type ClientCreated = { type: typeof CLIENT_CREATED, client: MatrixClient }
export type ClientStarted = { type: typeof CLIENT_STARTED, client: MatrixClient }
export type ClientInit = { type: typeof CLIENT_INIT, client: MatrixClient }
export type UserProfile = { type: typeof USER_PROFILE_EXTRACTED, displayname?: string, avatar_url?: string }

export type TimelineEvent = { type: typeof TIMELINE_EVENT, event: MatrixEvent }
export type RoomMemberEvent = { type: typeof ROOM_MEMBER_EVENT, client: MatrixClient, event: MatrixEvent }

export type RoomsExtracted = { type: typeof ROOMS_EXTRACTED, rooms: Room[] }
export type CreateRoom = { type: typeof CREATE_ROOM, title: string }
export type SyncRoomEvents = { type: typeof SYNC_ROOM_EVENTS, roomId: string }
export type RoomEventsSynced = { type: typeof ROOM_EVENTS_SYNCED, room: Room }

export type EventsSynced = { type: typeof EVENTS_SYNCED, events: IEventWithRoomId[] }
export type SendEvent = { type: typeof SEND_EVENT, roomId: string, body: any, eventType: string }
export type SendMessage = { type: typeof SEND_MESSAGE, roomId: string, text: any }

export type MatrixFact =
  | BaseUrlSet
  | UserIdSet
  | UserPasswordSet
  | AccessTokenSet
  | LoginTokenSet
  | DeviceIdSet
  | ClientCreated
  | ClientStarted
  | ClientInit
  | UserProfile
  | TimelineEvent
  | RoomMemberEvent
  | RoomsExtracted
  | CreateRoom
  | SyncRoomEvents
  | RoomEventsSynced
  | EventsSynced
  | SendEvent
  | SendMessage



const { query, join, store } = scope<MatrixFact>()

// const a = {
//   access_token: "syt_bWF4_moNawhryousDOgBmFKsP_2llSBq",
//   device_id: "OZGJHYOIYV",
//   home_server: "ulolo.app",
//   user_id: "@max:ulolo.app",
// }


export function initMatrixEvents(): MatrixFact[] {


  const userId = localStorage.getItem('userId') as string
  const deviceId = localStorage.getItem('deviceId') as string
  const accessToken = localStorage.getItem('accessToken') as string

  if (!userId) {
    return [{ type: HOMESERVER_SET, homeserver: 'https://matrix.ulolo.app/' },]
  } else {
    return [
      { type: HOMESERVER_SET, homeserver: 'https://matrix.ulolo.app/' },
      { type: USER_ID_SET, userId },
      { type: DEVICE_ID_SET, deviceId },
      { type: ACCESS_TOKEN_SET, accessToken },
    ]
  }
}

export const matrixStore = store({

  // clientLoggedIn: join({
  //   homeserver: query(HOMESERVER_SET).focus.homeserver.byKey(() => ''),
  //   user: query(USER_ID_SET).focus.userId.byKey(() => ''),
  //   password: query(USER_PASSWORD_SET).focus.password.byKey(() => '')
  // }).evalMap(async ({ user, password, homeserver }) => {
  //   const client = createClient({ baseUrl: homeserver })
  //   const login = await client.login('m.login.password', { user, password })
  //   const initSync = await client.roomInitialSync(roomId, 3000)
  //   return { client, messages: initSync.messages?.chunk, login }
  // }).process(CLIENT_LOGGED_IN),
  //
  clientByAccessToken: join({
    baseUrl: query(HOMESERVER_SET).focus.homeserver.byKey(() => ''),
    userId: query(USER_ID_SET).focus.userId.byKey(() => ''),
    accessToken: query(ACCESS_TOKEN_SET).focus.accessToken.byKey(() => ''),
    deviceId: query(DEVICE_ID_SET).focus.deviceId.byKey(() => ''),
  }).evalMap(async opts => {

    console.log('clientByAccessToken', JSON.stringify(opts, null, 2))
    const client = createClient(opts);
    return { client }
  }).process(CLIENT_CREATED),

  clientByLoginToken: join({
    homeserver: query(HOMESERVER_SET).focus.homeserver.byKey(() => ''),
    loginToken: query(LOGIN_TOKEN_SET).focus.loginToken.byKey(() => ''),
  }).once().evalMap(async ({ homeserver, loginToken }) => {
    const client = createClient({ baseUrl: homeserver })
    const login = await client.login('m.login.token', { token: loginToken })

    localStorage.setItem('userId', login.user_id)
    localStorage.setItem('deviceId', login.device_id)
    localStorage.setItem('accessToken', login.access_token)

    return { client }
  }).process(CLIENT_CREATED),

  clientCreated: query(CLIENT_CREATED).byKey(() => ''),

  clientStarted: query(CLIENT_CREATED).byKey(() => '')
    .evalMap(async ({ client }) => {
      await client.startClient()
      return { client }
    })
    .process(CLIENT_STARTED),

  clientInit: query(CLIENT_CREATED).byKey(() => '').once()
    .evalMap(({ client }) =>
      new Promise(resolve => {
        const cb = function (state: SyncState) {
          if (state === SyncState.Prepared) {
            console.log("clientInit", state, client.getRooms());

            client.off(ClientEvent.Sync, cb)
            resolve({ client })
          } else {
            console.log('syncstate', state);
          }
        }

        console.log('==== ClientEvent.Sync subscribed')
        client.on(ClientEvent.Sync, cb)
      })
    ).process(CLIENT_INIT),

  clientProfile: join({
    client: query(CLIENT_CREATED).focus.client.byKey(() => ''),
    userId: query(USER_ID_SET).focus.userId.byKey(() => ''),
  })
    .evalMap(({ client, userId }) => client.getProfileInfo(userId))
    .byKey(() => '')
    .process(USER_PROFILE_EXTRACTED)
    .publish(),

  timelineEventsPubAsync: query(CLIENT_CREATED).focus.client.byKey(() => '').once()
    .publishAsync(client => pub => {
      client.on(RoomEvent.Timeline, event => {

        pub({ type: TIMELINE_EVENT, event })
      })
    }),

  timelineEvents: query(TIMELINE_EVENT).byKey(() => ''),

  roomMemberEvents: query(CLIENT_CREATED).focus.client.byKey(() => '').once()
    .publishAsync(client => pub => {

      console.log('RoomStateEvent.Members subscribed')
      client.on(RoomStateEvent.Members, (event) => {
        console.log('RoomStateEvent.Members', client.getRooms(), event)

        pub({ type: ROOM_MEMBER_EVENT, client, event })
      })
    }),

  roomsExtracted: query(
    query(CLIENT_INIT).focus.client,
    query(ROOM_MEMBER_EVENT).focus.client
  )
    .map(client => ({ rooms: client.getRooms() }))
    .byKey(() => '')
    .publish(ROOMS_EXTRACTED),

  rooms: query(ROOMS_EXTRACTED).byKey(() => ''),

  roomCreated: join({
    client: query(CLIENT_STARTED).focus.client.byKey(() => '').once(),
    room: query(CREATE_ROOM).byKey(() => '')
  }).evalMap(({ client, room }) => {

    console.log('Create room', room)
    return client.createRoom({
      visibility: Visibility.Private,
      name: room.title,
      is_direct: false,
    })
  }),

  eventsSynced: join({
    client: query(CLIENT_STARTED).focus.client.byKey(() => ''),
    roomId: query(SYNC_ROOM_EVENTS).focus.roomId.byKey(() => '')
  })
    .evalMap(async ({ client, roomId }) => {

      console.log('Room before Initial sync', roomId)
      const events = await client.roomInitialSync(roomId, 3000)
      console.log('Room Initial sync', roomId, events)
      return { events: events.messages?.chunk }
    })
    .publish(EVENTS_SYNCED),

  sendEvent: join({
    client: query(CLIENT_STARTED).focus.client.byKey(() => ''),
    event: query(SEND_EVENT).byKey(() => '')
  }).evalMap(({ client, event }) =>
    client.sendEvent<any>(event.roomId, event.eventType, event.body)
  ),

  sendMessage: join({
    client: query(CLIENT_STARTED).focus.client.byKey(() => ''),
    event: query(SEND_MESSAGE).byKey(() => '')
  }).evalMap(({ client, event }) =>
    client.sendMessage(event.roomId, { msgtype: MsgType.Text, body: event.text })
  )

})

//
// export class MatrixEventSource<S extends { roomId: string } & HasType> implements EventSource<S> {
//
//   private eventType: string
//   private executor: StoreExecutor<MatrixFact>
//   private _seq = 0
//   private _subscribers: PackSubscriber<any, S>[] = [];
//
//
//   constructor(
//     _executor: StoreExecutor<MatrixFact>,
//     _eventType = 'app.ulolo.todo',
//   ) {
//     this.eventType = _eventType
//     this.executor = _executor
//
//
//     this.executor.listen(matrixStore.timelineEvents, event => {
//       if (!event['']?.event) return
//       if (event[''].event.getType() != this.eventType) return
//
//       this.process(event[''].event.getContent() as S)
//     })
//
//     this.executor.listen(matrixStore.eventsSynced, eventsSynced => {
//       const events = eventsSynced['']?.events.filter(e => e.type == this.eventType)
//       if (!_.isEmpty(events)) {
//         this.rehydrate(events.map(v => v.content as S))
//       }
//     })
//   }
//
//   process(event: S) {
//     this.executor.process({
//       type: SEND_EVENT,
//       eventType: this.eventType,
//       roomId: event.roomId,
//       body: event
//     })
//   }
//
//   subscribe(subs: PackSubscriber<any, S>): void {
//     this._subscribers = [...this._subscribers, ...[subs]]
//   }
//
//   private process(content: S) {
//
//     this._seq = this._seq + 1
//     const pack = fullPack('', content, this._seq, false, false)
//     _.forEach(this._subscribers, subs => subs(pack))
//   }
//
//   private rehydrate(events: S[]) {
//     this._seq = this._seq + events.length
//     const pack = _.map(events, ev => entry('', ev, this._seq, false, true))
//     console.log('rehydrating', pack)
//     _.forEach(this._subscribers, subs => subs(pack))
//   }
//
// }
//





