"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.prefixed = void 0;
const base_1 = require("../base");
const PREFIXED = 'PREFIXED';
function prefixed(prev, prefix) {
    return {
        type: PREFIXED,
        locale: prev.locale,
        keys: path => prev.keys((0, base_1.pathKey)(prefix, path)),
        state: path => prev.state((0, base_1.pathKey)(prefix, path)),
        value: path => prev.value((0, base_1.pathKey)(prefix, path)),
    };
}
exports.prefixed = prefixed;
