"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.map = void 0;
const Pack_1 = require("../Pack");
const MAPPED = 'MAPPED';
function map(prev, f) {
    return {
        type: MAPPED,
        locale: prev.locale,
        keys: prev.keys,
        state: path => prev.state(path).map((0, Pack_1.mapValuePack)(f)),
        value: path => keys => prev.value(path)(keys).map((0, Pack_1.mapValuePack)(f))
    };
}
exports.map = map;
