"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.fire = void 0;
const Pack_1 = require("../Pack");
const FIRE = 'FIRE';
function fire(prev, f) {
    const fire0 = (0, Pack_1.mapValuePack)((value, key, _seqId, deleted, noEffect) => {
        if (!deleted && !noEffect)
            f(value, key);
        return value;
    });
    return {
        type: FIRE,
        locale: prev.locale,
        keys: prev.keys,
        state: path => prev.state(path).map(fire0),
        value: prev.value
    };
}
exports.fire = fire;
