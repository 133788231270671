"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.StoreExecutor = void 0;
const _ = __importStar(require("lodash"));
const QueryTail_1 = require("./QueryTail");
const Context_1 = require("./Context");
const Reader_1 = require("./Reader");
const State_1 = require("./State");
const Pack_1 = require("./Pack");
const QueryStore_1 = require("./QueryStore");
class StoreExecutor {
    constructor(store, storeName = "", source = new QueryStore_1.LoopbackEventSource(), storage = new Context_1.InMemoryStorage()) {
        this._storageListeners = [];
        this.process = ({ events, noEffect }) => {
            if (_.isEmpty(events))
                return;
            this._current = this._current.then(() => {
                this._seq = this._seq + _.size(events);
                const pack = (0, Pack_1.fullPackFromArray)('', events, this._seq, false, noEffect);
                return State_1.State
                    .traverseObj(this._store, (qt) => Context_1.Context.setInlet(pack).asF(QueryTail_1.QueryTail.exec(qt)))
                    .runS(new Context_1.Context(this._storage).withOutletAsync(pack => {
                    this.publish(pack);
                    this.fireStorageListeners();
                }))
                    .then(ctx => {
                    const outletInEvents = ctx.outletIn.map(v => v.value);
                    const outletOutEvents = ctx.outletOut.map(v => v.value);
                    this.process({ events: outletInEvents, noEffect: false });
                    this._eventSource.publish(outletOutEvents, this.process);
                    this.fireStorageListeners();
                });
            });
        };
        this.fireStorageListeners = () => _.forEach(this._storageListeners, listener => listener.fire());
        this._store = store;
        this._storeName = storeName;
        this._storage = storage;
        this._eventSource = source;
        this._seq = 0;
        this._current = Promise.resolve(undefined);
        this._eventSource.subscribe(this.process);
        this._storage.listen(() => this.fireStorageListeners());
    }
    get storage() { return this._storage; }
    // public get context(): Context<S> { return new Context(this._storage) }
    get store() { return this._store; }
    get inprogress() { return this._current; }
    publish(event) {
        this.process({ events: [event], noEffect: false });
        this._eventSource.publish(event, this.process);
    }
    // public publishPack(pack: Pack<any, S>): void {
    //
    //   // switch (kind) {
    //   //   case "in": return this.process(pack)
    //   //   case "out": return this._eventSource.publish(pack, this.process)
    //   //   case "default":
    //   //     this.process(pack)
    //   //     this._eventSource.publish(pack, this.process)
    //   // }
    // }
    listen(qt, key, cb) {
        const listener = new QueryStore_1.StorageListener(qt, key, this._storage, cb);
        // if we trigger right away, all subs will receive null first
        // user needs to make sure listeners iniated before the first event is processed
        // but when it is impossible to initialize listener before - we have to fire
        listener.fire();
        this._storageListeners = [...this._storageListeners, ...[listener]];
    }
    unlisten(cb) {
        this._storage.unlisten(cb);
        this._storageListeners = _.filter(this._storageListeners, l => l.handler != cb);
    }
    read(qt, path = '') {
        return QueryTail_1.QueryTail.readRecord(qt, path).run(this._storage);
    }
    // TODO shouldn't be here
    readAll() {
        if (!this._store)
            return Promise.resolve({});
        else {
            return Reader_1.Reader.traverseObj(this._store, (qt, key) => QueryTail_1.QueryTail.readAll(qt)
                .map(pack => ({ [key]: Object.fromEntries(pack.entries()) }))).map(values => _.reduce(values, (acc, v) => (Object.assign(Object.assign({}, acc), v)), {})).run(this._storage);
        }
    }
}
exports.StoreExecutor = StoreExecutor;
