"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.evalMap = void 0;
const base_1 = require("../base");
const Pack_1 = require("../Pack");
const Context_1 = require("../Context");
const EVAL_MAP = 'EVAL_MAP';
function evalMap(prev, f) {
    const locale = (0, base_1.pathKey)(prev.locale, EVAL_MAP);
    return {
        type: EVAL_MAP,
        locale: locale,
        state: path => prev.state(path).evalMap((0, Pack_1.foldPack)(Promise.resolve((0, Pack_1.emptyPack)()), (acc, t, k, seqId, deleted, noEffect) => __awaiter(this, void 0, void 0, function* () {
            if (noEffect)
                return acc;
            else {
                const accPack = yield acc;
                const value = yield f(t, k);
                return (0, Pack_1.concatPack)(accPack, (0, Pack_1.fullPack)(k, value, seqId, deleted, noEffect));
            }
        }))).flatTap(Context_1.Context.writeByKeys((0, base_1.pathKey)(locale, path))),
        keys: Context_1.Context.allKeys(locale),
        value: Context_1.Context.valueByKeys(locale)
    };
}
exports.evalMap = evalMap;
