import React, { useEffect, useRef, useState } from 'react';
import { IonAvatar, IonButton, IonCard, IonCardContent, IonCardHeader, IonCardSubtitle, IonCardTitle, IonContent, IonFab, IonFabButton, IonIcon, IonItem, IonLabel, IonList, IonModal, IonNote, IonPage, IonText, IonTitle, IonToolbar } from '@ionic/react';
// import './Home.css';
import GoogleAuthButton from '../features/auth/components/GoogleAuthButton';
import LinkCard from '../components/LinkCard';
import { calendar, calendarNumberOutline, listCircle, search, searchCircle } from 'ionicons/icons';
import { logoGoogle } from 'ionicons/icons';
import * as _ from "lodash"


import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Navigation } from 'swiper/modules';
import ProgressBar from '../components/ProgressBar';
import UserAvatar from '../components/UserAvatar';


import { Sheet, SheetRef } from 'react-modal-sheet';
import ParallaxModal from '../components/ParallaxModal';
import { useListener } from 'qume-react';
import { matrixStore } from '../features/todo-matrix/service/MatrixEventSource';
import { useNavigate } from 'react-router';


const Login: React.FC = () => {

  const [start, setStart] = useState(0.2)

  const profile = useListener(matrixStore.clientProfile)
  const navigate = useNavigate()

  useEffect(() => {
    if (profile && !_.isEmpty(profile[''])) {
      navigate("/home")
    }
  }, [profile['']])

  const googleAuthUrl = `https://matrix.ulolo.app/_matrix/client/v3/login/sso/redirect?redirectUrl=${window.location.origin}/oauth/google?hs=martix.ulolo.app`
  return (

    <IonPage>
      <div id="page" className="min-h-screen bg-gradient-to-br from-indigo-500 to-purple-500">
        <div className="relative">

          <div className="px-8 pt-4 font-sans text-white">
            <div className="text-xl ">January, 15</div>
            {profile[''] && profile[''].displayname}
          </div>


        </div>

        <IonModal
          className='drop-shadow-md'
          isOpen={true}
          initialBreakpoint={start}
          canDismiss={false}
          breakpoints={[start, 1]}
          backdropBreakpoint={1}
          handleBehavior='cycle'
        >

          <div className='p-6 h-full' >

            <GoogleAuthButton />


          </div>


        </IonModal>

      </div >

    </IonPage >
  );
};

export default Login;

