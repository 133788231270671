"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.filter = void 0;
const Pack_1 = require("../Pack");
const FILTER = 'FILTER';
function filter(prev, f) {
    return {
        type: FILTER,
        locale: prev.locale,
        state: prefixPath => prev.state(prefixPath).map((0, Pack_1.flatMapPack)((v, k, seqId, _deleted, noEffect) => f(v, k) ? (0, Pack_1.fullPack)(k, v, seqId, false, noEffect) : (0, Pack_1.fullPack)(k, v, seqId, true, noEffect))),
        keys: prev.keys,
        value: prefixPath => keys => prev.value(prefixPath)(keys).map((0, Pack_1.flatMapPack)((v, k, seqId, _deleted, noEffect) => f(v, k) ? (0, Pack_1.fullPack)(k, v, seqId, false, noEffect) : (0, Pack_1.emptyPack)() // TODO: should still return value???
        ))
    };
}
exports.filter = filter;
