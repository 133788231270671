"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.flatten = void 0;
const Pack_1 = require("../Pack");
const _ = __importStar(require("lodash"));
const FLATTEN = 'FLATTEN';
function flatten(prev) {
    const flatten0 = (0, Pack_1.flatMapPack)((value, key, seqId, deleted, noEffect) => _.reduce(value, (acc, v) => (0, Pack_1.concatPack)(acc, (0, Pack_1.fullPack)(key, v, seqId, deleted, noEffect)), (0, Pack_1.emptyPack)()));
    return {
        type: FLATTEN,
        locale: prev.locale,
        state: prefixPath => prev.state(prefixPath).map(flatten0),
        keys: prev.keys,
        value: prefixPath => keys => prev.value(prefixPath)(keys).map(flatten0)
    };
}
exports.flatten = flatten;
