import React, { useCallback, useEffect, useRef, useState } from 'react';
import { CheckboxChangeEventDetail, IonBackButton, IonButton, IonButtons, IonCard, IonCardContent, IonCardHeader, IonCardSubtitle, IonCardTitle, IonCheckbox, IonChip, IonContent, IonHeader, IonIcon, IonInput, IonItem, IonLabel, IonList, IonModal, IonPage, IonTitle, IonToggle, IonToolbar, ToggleChangeEventDetail, ToggleCustomEvent, useIonActionSheet } from '@ionic/react';
import { useListener, usePublisher, } from 'qume-react';
import { CREATE_TODO, CreateToDo, SEND_TODO_MESSAGE, SYNC_TODO_LIST, TODO_ACTIVATED, TODO_COMPLETED, ToDoFact } from '../features/todo-matrix/service/types';
import { useNavigate, useParams } from 'react-router';
import { useSearchParams } from 'react-router-dom';
import * as _ from "lodash"
import { MatrixFact, matrixStore, SYNC_ROOM_EVENTS } from '../features/todo-matrix/service/MatrixEventSource';
import { todoStore } from '../features/todo-matrix/service';
import { addCircle, addCircleOutline, arrowUpCircle, chevronBackOutline, menuOutline } from 'ionicons/icons';


const List: React.FC = () => {

  const page = useRef(undefined);
  const { listId } = useParams<{ listId: string }>()

  if (!listId) return (
    <IonPage> Loading </IonPage>
  )

  useEffect(() => {
    publish({ type: SYNC_TODO_LIST, listId })
  }, [listId])


  const navigate = useNavigate();

  const [text, setText] = useState('');

  const sendTodo = useCallback(() => {
    if (text && listId) {
      setText('')
      publish({ type: CREATE_TODO, listId: listId, title: text })
    }
  }, [text])

  const sendMessage = useCallback(() => {
    if (text && listId) {
      setText('')
      publish({ type: SEND_TODO_MESSAGE, listId: listId, text })
    }
  }, [text])


  const modalHeight = 100
  const startBreakpoint = modalHeight / window.innerHeight
  const openBreakpoint = 0.75
  const modal = useRef<HTMLIonModalElement>(null);
  const [presentingElement, setPresentingElement] = useState<HTMLElement | undefined>(undefined);
  const [breakpoint, setBreakpoint] = useState(startBreakpoint)

  function switchModal() {
    if (breakpoint == startBreakpoint) {
      modal.current?.setCurrentBreakpoint(openBreakpoint)
    } else {
      modal.current?.setCurrentBreakpoint(startBreakpoint)
    }
  }

  function onCheckBoxChange(id: string): (ev: CustomEvent<CheckboxChangeEventDetail>) => void {
    return ev => {
      if (!listId) return
      if (ev.detail.checked) publish({ type: TODO_COMPLETED, listId: listId, id })
      else publish({ type: TODO_ACTIVATED, listId: listId, id })
    }
  }

  const publish = usePublisher<ToDoFact>()
  // const room = useListener(matrixStore.roomSelected)
  // const roomName = room['']?.room.name

  const all = useListener(todoStore.all, listId)
  const list = useListener(todoStore.lists, listId)
  const listTitle = list[listId]?.title

  const messagesObj = useListener(todoStore.messages, listId)
  const messages = messagesObj[listId]

  return (
    <IonPage ref={page}>

      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonButton onClick={() => navigate(-1)}>
              <IonIcon slot="icon-only" icon={chevronBackOutline}></IonIcon>
            </IonButton>
          </IonButtons>
          <IonTitle>{listTitle}</IonTitle>
          <IonButtons slot="end">
            <IonButton strong={true} >
              <IonIcon className='hidden' slot="icon-only" icon={menuOutline}></IonIcon>
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>


      <div className='h-full relative'>


        <IonContent>
          <div className="p-4 flex flex-col items-start gap-2">
            {messages && messages.map((message) => (
              <IonChip key={message.id} color="primary">
                <IonLabel>{message.text}</IonLabel>
              </IonChip>
            ))}
          </div>
        </IonContent>


        <IonModal
          ref={modal}
          isOpen={true}
          initialBreakpoint={startBreakpoint}
          canDismiss={false}
          breakpoints={[startBreakpoint, openBreakpoint, 1]}
          backdropBreakpoint={startBreakpoint}
          onIonBreakpointDidChange={ev => setBreakpoint(ev.detail.breakpoint)}
        >
          <IonContent >

            <div>
              <IonList inset={true} >
                <IonItem color='light'>
                  {
                    _.isEmpty(text)
                      ? <IonIcon color='primary' icon={menuOutline} slot="start" size='large' onClick={switchModal} />
                      : <IonIcon color='primary' icon={addCircleOutline} slot="start" size='large' onClick={sendTodo} />
                  }
                  <input type='text' placeholder='Message' className='w-full focus:outline-none bg-opacity-0 bg-white'
                    value={text} onChange={e => setText(e.target.value)} />
                  <IonIcon color='primary' icon={arrowUpCircle} slot="end" size='large' onClick={sendMessage} />
                </IonItem>
              </IonList>

              <IonList > {
                _.isEmpty(all)
                  ? <IonItem> No items </IonItem>
                  : _.map(all, (todo) => {
                    return (
                      <IonItem className='pl-2' key={todo.id}>
                        <IonCheckbox
                          labelPlacement="start"
                          checked={!todo.active}
                          onIonChange={onCheckBoxChange(todo.id)}>
                          {todo.title}
                        </IonCheckbox>
                      </IonItem>
                    )
                  })
              } </IonList>
            </div>

          </IonContent>
        </IonModal>


      </div>
    </IonPage >
  );
};

export default List;
