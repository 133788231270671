import React, { useCallback, useEffect, useRef, useState } from 'react';
import { createGesture, Gesture, IonAvatar, IonButton, IonButtons, IonCard, IonCardContent, IonCardHeader, IonCardSubtitle, IonCardTitle, IonContent, IonFab, IonFabButton, IonHeader, IonIcon, IonInput, IonItem, IonLabel, IonList, IonModal, IonNote, IonPage, IonSearchbar, IonText, IonTitle, IonToolbar } from '@ionic/react';
// import './Home.css';
import GoogleAuthButton from '../features/auth/components/GoogleAuthButton';
import LinkCard from '../components/LinkCard';
import { calendar, calendarNumberOutline, createOutline, listCircle, search, searchCircle } from 'ionicons/icons';
import { logoGoogle } from 'ionicons/icons';
import * as _ from "lodash"


import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Navigation } from 'swiper/modules';
import ProgressBar from '../components/ProgressBar';
import UserAvatar from '../components/UserAvatar';


import { Sheet, SheetRef } from 'react-modal-sheet';
import ParallaxModal from '../components/ParallaxModal';
import { useListener, usePublisher } from 'qume-react';
import { OverlayEventDetail } from '@ionic/react/dist/types/components/react-component-lib/interfaces';
import { CREATE_TODO, CREATE_TODO_LIST, ToDoFact } from '../features/todo-matrix/service/types';
import { todoStore } from '../features/todo-matrix/service';
import { useNavigate } from 'react-router';

const isPWAFullscreen = () => {
  // Check if running in standalone mode (iOS and Android)
  const isStandalone = window.matchMedia('(display-mode: standalone)').matches ||
    (window.navigator as any).standalone

  // Check if running in fullscreen mode
  const isFullscreen = window.matchMedia('(display-mode: fullscreen)').matches;

  return isStandalone || isFullscreen;
};

const Home: React.FC = () => {

  const navigate = useNavigate();

  const [max, setMax] = useState(false)
  const [newListName, setNewListName] = useState('');


  // const start = 0.5
  const [start, setStart] = useState(0.3)
  const open = 0.95
  const page = useRef(null);
  const [presentingElement, setPresentingElement] = useState<HTMLElement | null>(null);

  useEffect(() => {
    setPresentingElement(page.current);
  }, []);

  const publish = usePublisher<ToDoFact>()
  const user = useListener(todoStore.user)
  const displayname = user && user[''] && user[''].name

  const lists = useListener(todoStore.lists)


  const modalLists = useRef<HTMLIonModalElement>(null);

  const modal = useRef<HTMLIonModalElement>(null);


  const create = useCallback(() => {

    if (newListName) {
      publish({ type: CREATE_TODO_LIST, title: newListName })
      modal.current?.dismiss();
      // navigate(`/todo-matrix/chat/${id}`,)
    }
  }, [newListName])

  const ionPageClassName = `min-h-screen  ${isPWAFullscreen() ? 'pt-8' : ''} drop-shadow-md bg-gradient-to-b from-indigo-600 to-purple-600 rounded-t-3xl`

  return (

    <IonPage ref={page}>
      <div id="page" className={ionPageClassName} >
        <div className="relative">

          <div className="px-8 pt-4 flex">
            <div className="font-sans text-white flex-auto">
              <div className="text-xl ">January, 15</div>
              <p className="text-4xl font-bold drop-shadow-md">{displayname}</p>
              <p>{isPWAFullscreen() ? 'PWA' : 'Web'}</p>
            </div>

            <IonIcon id='open-modal' className="text-4xl drop-shadow-md" color="light" icon={createOutline}></IonIcon>
            <IonModal ref={modal} trigger='open-modal' presentingElement={presentingElement!} >
              <IonHeader>
                <IonToolbar>
                  <IonButtons slot="start">
                    <IonButton onClick={() => modal.current?.dismiss()}>Cancel</IonButton>
                  </IonButtons>
                  <IonTitle>New todo</IonTitle>
                  <IonButtons slot="end">
                    <IonButton strong={true} onClick={() => create()}> Create </IonButton>
                  </IonButtons>
                </IonToolbar>
              </IonHeader>
              <IonContent>
                <IonList inset={true} >
                  <IonItem color='light'>
                    <input type='text' placeholder='New list' className='w-full focus:outline-none bg-opacity-0 bg-white'
                      value={newListName} onChange={e => { console.log(e.target.value); setNewListName(e.target.value) }} />
                  </IonItem>
                </IonList>
                {newListName}
              </IonContent>
            </IonModal>
          </div>

          <div className='drop-shadow-md'>
            <IonList inset={true} >
              <IonItem color='light'>
                <input type='text' placeholder='Search'
                  className='w-full focus:outline-none ml-4 bg-opacity-0 bg-white' />
                <IonIcon icon={search} color='medium' slot="end" size='large' />
              </IonItem>
            </IonList>
          </div>

          <div className="px-8 pt-2 font-sans text-white">
            <p className="text-2xl font-bold">Favorites</p>
          </div>

          <div>
            <Swiper
              slidesPerView={2.5}
              spaceBetween={0}
            >
              {_.map([1, 2, 3, 4, 5], idx =>
                <SwiperSlide key={idx}>
                  <IonCard className="m-2 bg-gradient-to-br from-indigo-500 to-purple-500">
                    <IonCardHeader>
                      <ProgressBar />
                      <IonCardSubtitle color="light">Some todo</IonCardSubtitle>
                    </IonCardHeader>
                    <IonCardContent className='p-4'>
                    </IonCardContent>

                  </IonCard>
                </SwiperSlide>
              )}

            </Swiper>
          </div>
        </div>

        <IonModal
          ref={modalLists}
          className='drop-shadow-md'
          isOpen={true}
          initialBreakpoint={start}
          canDismiss={false}
          breakpoints={[start, open]}
          backdropBreakpoint={open}
          handleBehavior='cycle'
          onIonBreakpointDidChange={ev => setMax(ev.detail.breakpoint == open)}


        >

          <IonContent>

            <div className='m-2'>
              <div > <IonSearchbar></IonSearchbar> </div>


              <IonList >
                {_.map(lists, room =>
                  <IonItem key={room.id} href={`/lists/${room.id}`} button detail={false} lines="none" className="py-2">
                    <div className='mr-2'>
                      <UserAvatar name={room.title} />
                    </div>
                    <IonLabel>
                      <h1>{room.title}</h1>
                      <p>some text over {room.title}</p>
                    </IonLabel>
                    <IonNote slot="end">6</IonNote>
                  </IonItem>
                )}
              </IonList>
            </div>



          </IonContent>

        </IonModal>

      </div >

    </IonPage >
  );
};

export default Home;

// <ParallaxModal >
//   {/* Modal content */}
//   <div className="p-8">
//     <h2 className="text-2xl font-bold mb-4">Modal Title</h2>
//     <p>Modal content goes here...</p>
//   </div>
// </ParallaxModal>

// <SwiperSlide className="bg-white rounded-lg shadow-lg m-4 p-6">Card 1</SwiperSlide>
//
//
// <SwiperSlide className="bg-white rounded-lg shadow-lg m-4 p-6">Card 2</SwiperSlide>
// <SwiperSlide className="bg-white rounded-lg shadow-lg m-4 p-6">Card 3</SwiperSlide>
// <SwiperSlide className="bg-white rounded-lg shadow-lg m-4 p-6">Card 4</SwiperSlide>
// <SwiperSlide className="bg-white rounded-lg shadow-lg m-4 p-6">Card 5</SwiperSlide>
// <SwiperSlide ></SwiperSlide>
